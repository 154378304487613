import React from 'react';

import Text from 'components/core/Text';
import {
  AchievementSessionsType,
  AchievementWeekStreakType,
  Activity as ActivityProps,
} from 'reducers/activities/types';
import AchievementsTypes from 'components/Achievements';
import moment from 'moment';
import {
  Wrapper,
  Preview,
  PreviewWrapper,
  ProgramSession,
  ProgramNameWrapper,
  SessionDate,
} from './styles';

type AchievementActivityProps = Pick<
  ActivityProps,
  'name' | 'description' | 'completedAt' | 'compact'
> &
  Required<Pick<ActivityProps, 'achievement'>>;

const getAchievementBadge = (achievement: AchievementActivityProps['achievement']) => {
  if (achievement.type === 5) {
    return AchievementsTypes.type_4.value_4.earned;
  }

  if (achievement.type === 4) {
    return AchievementsTypes.type_4.value_1.earned;
  }

  if (achievement.type === 2) {
    const achievementEntity = achievement as AchievementWeekStreakType;
    return AchievementsTypes.type_2[`value_${achievementEntity.timesValue}`].earned;
  }

  if (achievement.type === 1) {
    const achievementEntity = achievement as AchievementSessionsType;
    return AchievementsTypes.type_1[`value_${achievementEntity.timesValue}`].earned;
  }

  return null;
};

const AchievementActivity = ({
  name,
  description,
  completedAt,
  achievement,
  compact,
}: AchievementActivityProps) => {
  const AchievementComponent = getAchievementBadge(achievement);

  return (
    <Wrapper href="/profile" compact={compact}>
      <div>
        <ProgramNameWrapper marginBottom="16px">
          <Text variant="captionSmall">Achievement earned</Text>
          {completedAt && (
            <SessionDate variant="captionSmall">
              {moment(completedAt).format('dddd, MMM DD')}
            </SessionDate>
          )}
        </ProgramNameWrapper>
        <PreviewWrapper>
          <Preview as="div" height="auto">
            {AchievementComponent && <AchievementComponent style={{ width: 64, height: 64 }} />}
          </Preview>
          <div>
            <Text
              variant="textSmall"
              tag="h3"
              weight="bold"
              color="black"
              transform="uppercase"
              mb={2}
            >
              🏅 {name} achieved
            </Text>
            <ProgramSession variant="textSmall" compact={compact}>
              {description}
            </ProgramSession>
          </div>
        </PreviewWrapper>
      </div>
    </Wrapper>
  );
};

export default React.memo(AchievementActivity);
