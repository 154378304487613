import styled, { css } from 'styled-components';

import Link from 'components/Link';
import Text from 'components/core/Text';
import colors from 'constants/colors';
import { pictograms } from '../../constants';

interface CompactActivityViewProps {
  compact?: boolean;
}

export const Wrapper = styled(Link)<CompactActivityViewProps>`
  display: flex;
  justify-content: space-between;
  padding: 16px;
  width: 100%;
  border-radius: 8px;

  &:hover {
    background-color: ${colors.main.secondaryBtns};
  }

  @media screen and (max-width: 992px) {
    background-color: ${colors.main.secondaryBtns};
  }

  @media screen and (max-width: 400px) {
    flex-wrap: wrap;
  }

  ${({ compact }) =>
    compact &&
    css`
      padding: 12px;
    `}
`;

export const PreviewWrapper = styled.div`
  display: flex;
`;

export const Metrics = styled.div`
  display: grid;
  grid-template-columns: auto 1fr;
  align-items: center;
  height: min-content;
  gap: 8px;
`;

export const ExerciseMetrics = styled.div`
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  min-width: 64px;
  text-align: right;

  @media screen and (max-width: 400px) {
    width: 100%;
    padding-top: 16px;
    flex-direction: row;
    justify-content: space-around;
    text-align: center;
  }
`;

export const ProgramSession = styled(Text)<CompactActivityViewProps>`
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  max-height: 5em;

  ${({ compact }) =>
    compact &&
    css`
      -webkit-line-clamp: 2;
      max-height: 3em;
    `}
`;

interface ProgramNameWrapperProps {
  marginBottom?: string;
}

export const ProgramNameWrapper = styled.div<ProgramNameWrapperProps>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: ${({ marginBottom }) => marginBottom || '8px'};
`;

export const SessionDate = styled(Text)`
  padding: 4px 8px;
  border-radius: 8px;
  background-color: ${colors.main.secondaryBtns};
`;

interface PictogramProps {
  icon: string;
}

export const Pictogram = styled.div<PictogramProps>`
  height: 20px;
  width: 20px;

  ${props =>
    props.icon
      ? css`
          background: url(${pictograms[props.icon]}) center center no-repeat;
        `
      : css`
          background: none;
        `}

  background-size: contain;
`;

interface PreviewProps {
  height?: string;
}

export const Preview = styled.img<PreviewProps>`
  width: 80px;
  height: ${({ height }) => height || '80px'};
  border-radius: 8px;
  object-fit: cover;
  margin-right: 16px;
`;
